import { Api } from "api";

export const defaultConfig = {
  base: ({
    amsSite: {
      enableLoadAccount: true,
      enableWaiver: true,
      siteWaiver: {
        canSkip: true,
        siteId: "test-mayo",
        version: "v1",
        waiverUrl: "",
      },
    },
    id: "test-mayo",
    name: "test-mayo",
  } as unknown) as Api["SiteWeb"],
  google: ({
    amsSite: {
      enableLoadAccount: true,
      enableWaiver: true,
      siteTheme: {
        currencySign: "$",
        footerUrl: "https://images1.the-dots.com/2440667/the-dots-background.png?p=cover",
        headerColor: "1",
        headerUrl: "https://logos-world.net/wp-content/uploads/2020/09/Google-Logo.png",
        //  loginVideoLink: "https://www.youtube.com/watch?v=lmWy1QI403A",
        logoNavUrl:
          "https://seeklogo.net/wp-content/uploads/2020/07/Google-Photos-new-logo-2020-vector.png",
      },
      siteWaiver: {
        canSkip: true,
        siteId: "test-mayo",
        version: "v1",
        waiverUrl: "https://policies.google.com/terms?hl=en-CA",
      },
    },
    id: "test-mayo",
    name: "test-mayo",
  } as unknown) as Api["SiteWeb"],
} as const;
