import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modal";

import { Payment } from "./Payment";

import type { PaymentResponse } from "./Payment";

interface Props {
  onSuccess?: (res: PaymentResponse) => void;
  onRequestClose?: () => void;
  isOpen?: boolean;
}

export const PaymentModal: FC<Props> = ({ isOpen, onSuccess, onRequestClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      actions={null}
      isOpen={!!isOpen}
      title={t("NEW_PAYMENT")}
      onRequestClose={onRequestClose}
    >
      <Payment onSuccess={onSuccess} />
    </Modal>
  );
};
