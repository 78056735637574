import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Account } from "api";
import { useConfig, useCustomer } from "state";
import { Card } from "components/Card";
import { NoData } from "components/Result";
import { getHistory } from "helpers/history";
import { Title } from "components/Typography";

import { ReloadCard } from "./components/ReloadCard";
import { message } from "antd";

export const Reload: FC = () => {
  const { t } = useTranslation();
  const config = useConfig();
  const siteTheme = config.amsSite?.siteTheme;
  const reloadTitle = siteTheme?.labels?.["label.reload.title"];

  const customer = useCustomer();
  const list = (((customer && customer.accounts) || []) as Account[]).filter(
    (it) => it.loadAccountAllowed
  );

  const handleLoad = (it: Account) => () => {
    getHistory().pushWithTheme(`/reload/manual/${it.accountId}`);
  };

  const handleAutoLoad = (it: Account) => () => {
    const methods =
      customer && customer.savedPayments && customer.savedPayments.length > 0
        ? customer.savedPayments
        : [];

    methods.length > 0
      ? getHistory().pushWithTheme(`/reload/auto/${it.accountId}`)
      : message.warning(t("ADD_PAYMENT_METHOD_TO_SETUP_AUTOLOAD"));
  };

  return (
    <>
      <Title level={1}>{reloadTitle ? reloadTitle : t("RELOAD")}</Title>
      {list.map((it) => (
        <ReloadCard
          account={it}
          key={it.accountId}
          onAutoload={handleAutoLoad(it)}
          onLoad={handleLoad(it)}
        />
      ))}
      {list.length === 0 && (
        <Card>
          <NoData description={t("NO_ACCOUNTS_AVAILABLE_TO_RELOAD")} />
        </Card>
      )}
    </>
  );
};
