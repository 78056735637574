import cn from "classnames";
import React, { FC } from "react";
import { Button as AntButton } from "antd";

import styles from "./button.module.css";

export interface Props {
  type?: "primary" | "secondary" | "outline" | "link" | "text" | "danger";
  htmlType?: "submit" | "button" | "reset";
  icon?: string; // TODO: connect with Icon component
  onClick?: () => void;
  noPadding?: boolean; // TODO: create Link component instead
  className?: string;
  disabled?: boolean;
  block?: boolean;
  size?: "small" | "default" | "large";
}

export const Button: FC<Props> = ({
  htmlType = "button",
  type = "primary",
  noPadding,
  className,
  disabled,
  children,
  ...props
}) => {
  return (
    <AntButton
      {...props}
      className={cn(
        styles[type],
        styles.btn,
        {
          [styles.disabled]: !!disabled,
          [styles.text]: type === "text",
          [styles.noPadding]: noPadding,
        },
        className
      )}
      data-testid="button"
      disabled={!!disabled}
      htmlType={htmlType}
      // @ts-ignore TODO: ... antd ....
      type={antButtonType[type]}
    >
      {children}
    </AntButton>
  );
};

const antButtonType = {
  danger: "danger",
  link: "link",
  outline: "default",
  primary: "primary",
  secondary: "text",
  text: "link",
};
