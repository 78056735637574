import cn from "classnames";
import { Redirect, useRouteMatch } from "react-router-dom";
import React, { FC } from "react";
import { useLoggedIn } from "state";
import { useConfig } from "state/helpers/useConfig";

import styles from "./auth.module.css";
import { useTranslation } from "react-i18next";

export interface Props {
  left?: boolean;
}

export const Auth: FC<Props> = ({ children }) => {
  const isLoggedIn = useLoggedIn();
  const config = useConfig();
  const {
    params: { theme },
  } = useRouteMatch<{ theme: string }>();
  const { t } = useTranslation();

  const siteTheme = config.amsSite?.siteTheme;
  // const footerImg = config.amsSite?.siteTheme?.footerUrl;
  const loginLogo = siteTheme?.headerUrl;
  const helpText = siteTheme?.labels?.["message.welcome.info"];

  const email =
    siteTheme?.labels?.["label.footer.contact.email"] !== undefined
      ? siteTheme?.labels?.["label.footer.contact.email"]
      : siteTheme?.labels?.["label.footer.contact.phone"] !== undefined
      ? ""
      : "support@volantesystems.com";
  const phone =
    siteTheme?.labels?.["label.footer.contact.phone"] !== undefined
      ? siteTheme?.labels?.["label.footer.contact.phone"]
      : siteTheme?.labels?.["label.footer.contact.email"] !== undefined
      ? ""
      : "+1 877 490 6333";

  const description =
    email.match(/^ *$/) !== null &&
    phone.match(/^ *$/) !== null /* returns true if string is empty or consists of spaces */
      ? ""
      : siteTheme?.labels?.["label.footer.contact.description"] || t("CONTACT_US_MESSAGE");

  if (isLoggedIn) {
    return <Redirect to={`/${theme}/wallet-dashboard`} />;
  }
  return (
    <div className={cn(styles.authLayout, { [styles.withLogo]: loginLogo })}>
      <div className={styles.content}>
        {loginLogo && <img alt="brand" src={loginLogo} />}
        {children}
        {helpText && (
          <div
            className={"mt-3 text-center"}
            dangerouslySetInnerHTML={{ __html: String(helpText) }}
          />
        )}
      </div>
      {/*{footerImg && (*/}
      {/*  <footer className={styles.footer} style={{ backgroundImage: `url('${footerImg}')` }} />*/}
      {/*)}*/}
      <div className={styles.help}>
        <span className={styles.element}>{description}</span>
        <span className={cn(styles.element, styles.contactInfo)}>{email}</span>
        <span className={cn(styles.element, styles.contactInfo)}>{phone}</span>
      </div>
    </div>
  );
};
