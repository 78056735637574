import React, { FC } from "react";
import MultiCarousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { Account } from "api";

import "./account-list.css";
import { Pagination } from "./components/Pagination";
import { AccountCard } from "./components/AccountCard";

interface Props {
  onReload?: (account: Account) => void;
  accounts: Account[];
}

export const AccountsList: FC<Props> = ({ onReload, accounts }) => {
  return (
    <div className="mb-3 account-list-root">
      <MultiCarousel
        renderButtonGroupOutside
        arrows={false}
        className="account-list mb-4"
        customButtonGroup={<Pagination />}
        draggable={false}
        keyBoardControl={false}
        responsive={responsive}
      >
        {accounts.map((account) => {
          return <AccountCard account={account} key={account.accountId} onReload={onReload} />;
        })}
      </MultiCarousel>
    </div>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
};
