import * as Yup from "yup";

export function priceValidator(
  this: Yup.TestContext,
  value: string | null
): boolean | Yup.ValidationError {
  const { createError, path } = this;
  if (value && !/^[0-9.]*$/.test(value)) {
    return createError({
      message: "Price could contain only numbers or .",
      path,
    });
  }

  if (value && (/[.]{2,}/.test(value) || value?.split(".").length > 2)) {
    return createError({
      message: "Price cannot contain more than one delimitter .",
      path,
    });
  }

  return true;
}

export function passwordValidator(
  this: Yup.TestContext,
  value: string | null
): boolean | Yup.ValidationError {
  const { createError, path } = this;

  if (value && /['\\$]+/.test(value)) {
    return createError({
      message: "Value cannot contain ' \\ $",
      path,
    });
  }

  return true;
}
