import { useField } from "formik";
import React, { FC, ReactNode } from "react";

import { TextField } from "./fields";

interface Props {
  [key: string]: unknown;
  component: typeof TextField; // TODO: create generic input type
  helperText?: string;
  required?: boolean;
  label: ReactNode;
  name: string;
  transformValue?: (val: string) => string;
}

export const Field: FC<Props> = ({
  component: C,
  required,
  label,
  name,
  helperText,
  transformValue,
  ...props
}) => {
  const [{ value, onChange }, { error, touched }, { setTouched }] = useField(name);

  return (
    <C
      {...props}
      error={!!touched && !!error}
      helperText={!!touched && !!error ? error : helperText}
      label={required ? <>{label}*</> : label}
      name={name}
      value={value}
      onBlur={() => setTouched(true)}
      onChange={(e) => {
        e.target.value = transformValue ? transformValue(e.target.value) : e.target.value;
        onChange(e);
      }}
    />
  );
};
