// core
import React, { FC, Suspense } from "react";
import { getThemeStyles, Provider, useConfig, WatchConfig, WatchTheme } from "state";
// components
import {
  AutoReload,
  ManualReload,
  WalletDashboard,
  Login as LoginPage,
  Logout as LogoutPage,
  Reload as ReloadPage,
  SignUp as SignUpPage,
  Profile as ProfilePage,
  ResetSuccess as ResetSuccessPage,
  ForgotSuccess as ForgotSuccessPage,
  ResetPassword as ResetPasswordPage,
  ForgotPassword as ForgotPasswordPage,
  DomainNotFound as DomainNotFoundPage,
} from "pages";
import { App as AppLayout } from "layouts";
import { LoginWaiver } from "containers/LoginWaiver";
// libs
import { WatchLocale } from "./containers/Yup";
// nav
import { Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
// utils
import { components } from "./api-schema";
import { getHistory, WatchPath } from "helpers";
import { SsoRedirectPage } from "./pages/auth/SsoRedirectPage";

// Faster css load - no blink for development mode (localhost)
if (process.env.NODE_ENV === "development") {
  require("./themes/default.css");
}

export const App: FC = () => {
  return (
    <Suspense fallback={"..."}>
      <Router history={getHistory()}>
        <WatchPath />
        <WatchLocale />

        <Provider ignoreInit>
          <LoginWaiver />
          <WatchConfig />
          <Route component={WatchTheme} path="/:theme" />

          <ThemeContainer>
            <Switch>
              <Route exact component={DomainNotFoundPage} path="/error" />
              <Route exact component={LoginPage} path="/:theme/login" />
              <Route exact component={LogoutPage} path="/:theme/logout" />
              <Route exact component={ForgotPasswordPage} path="/:theme/forgot-password" />
              <Route exact component={ForgotSuccessPage} path="/:theme/forgot-password-success" />
              <Route exact component={ResetPasswordPage} path="/:theme/reset-password" />
              <Route exact component={ResetSuccessPage} path="/:theme/reset-password-success" />
              <Route exact component={SignUpPage} path="/:theme/sign-up" />
              <Route exact component={SsoRedirectPage} path={"/:theme/sso/callback"} />

              <Route
                path="/:theme"
                render={({
                  match: {
                    params: { theme },
                  },
                }) => (
                  <AppLayout>
                    <Switch>
                      <Route component={WalletDashboard} path="/:theme/wallet-dashboard" />
                      <Route component={ProfilePage} path="/:theme/profile" />
                      <Route component={ManualReload} path="/:theme/reload/manual/:id" />
                      <Route component={AutoReload} path="/:theme/reload/auto/:id" />
                      <Route exact component={ReloadPage} path="/:theme/reload" />
                      <Redirect to={`/${theme}/wallet-dashboard`} />
                    </Switch>
                  </AppLayout>
                )}
              />

              <Redirect to={`/${process.env.REACT_APP_DEFAULT_SITE_NAME || "default"}`} />
            </Switch>
          </ThemeContainer>
        </Provider>
      </Router>
    </Suspense>
  );
};

/*
 * if theme is loaded -- pass it to children
 * otherwise redirect to error page
 * */
const ThemeContainer: FC = ({ children }) => {
  const config: {
    id: string;
    name: string;
    amsSite?: components["schemas"]["AmsSite"];
    error?: string;
  } = useConfig();
  const history = useHistory();

  // if config has error -- site request failed, means the url is wrong
  if (config.error) {
    history.replace("/error");
  }
  const styles = getThemeStyles(config.name);
  if (styles) {
    styles();
    document.documentElement.classList.add(`${config.name}-theme`);
  }

  return <>{children}</>;
};

export const parseBrowserLanguage = (locale?: string): string => {
  if (!locale || locale?.startsWith("en")) {
    return "en";
  } else if (locale.startsWith("fr")) {
    return "fr";
  }
  return "en";
};
