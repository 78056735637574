import { Api } from "api";
import { message } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";
import type { Account } from "api";
import { useCustomer, useLazyApiCall } from "state";
import { apiServices, DEFAULT_PAGE_SIZE, userFullName } from "helpers";
import { RenderDesktop, RenderMobile } from "components/RenderMatchMedia";
import { ReceiptModal } from "./Receipt/ReceipModal";
import { TransactionsList } from "../components/TransactionsList";
import { ITransactionFilter, TransactionsTable } from "../components/TransactionsTable";
import { refreshCustomer } from "../../../state/effects/refreshCustomer";

export const TransactionsContainer: FC = () => {
  const [filterState, setFilterState] = useState({
    filterAccountId: "all",
    filterEndTime: new Date(new Date().setDate(new Date().getDate())).toISOString(),
    filterStartTime: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
    page: 0,
  });

  const customer = useCustomer();
  const clientFullName = userFullName(customer);
  const accounts = (customer?.accounts || []).filter(Boolean) as Account[];

  const { dispatch, data: res, error, isLoading } = useLazyApiCall<Api["PageTransSummary"]>(
    apiServices.transactions(
      filterState.filterAccountId === "all"
        ? accounts.map((i) => i.accountId || "")
        : [filterState.filterAccountId],
      DEFAULT_PAGE_SIZE,
      filterState.page,
      filterState.filterStartTime,
      filterState.filterEndTime
    ),
    // replaced customer fetch in WallerDashboard.tsx with this because for some reason
    // customer fetch did not trigger from WalletDashboard
    // TODO @YK: try to find a better solution
    refreshCustomer
  );

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(undefined, -1);
    }
    // eslint-disable-next-line
  }, [filterState]);

  const handleFilter = useCallback((filter: ITransactionFilter) => {
    if (!isLoading && filter !== filterState) {
      setFilterState((state) => {
        return {
          filterAccountId: filter.filterAccountId ?? state.filterAccountId,
          filterEndTime: filter.filterEndTime ?? state.filterEndTime,
          filterStartTime: filter.filterStartTime ?? state.filterStartTime,
          page: filter.page ?? state.page,
        };
      });
    }
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = useState<Api["TransSummary"]>();

  const handleSelectTransaction = (row: Api["TransSummary"]) => {
    setSelected(row);
  };

  return (
    <>
      <RenderDesktop>
        <TransactionsTable
          accounts={accounts}
          clientFullName={clientFullName}
          data={res?.content ?? []}
          filter={filterState}
          isLoading={isLoading}
          totalLength={res?.totalElements ?? 0}
          onChangeFilter={handleFilter}
          onSelect={handleSelectTransaction}
        />
      </RenderDesktop>

      <RenderMobile>
        <TransactionsList
          accounts={accounts}
          data={res?.content ?? []}
          filter={filterState}
          isLoading={isLoading}
          totalLength={res?.totalElements ?? 0}
          onChangeFilter={handleFilter}
          onSelect={handleSelectTransaction}
        />
      </RenderMobile>

      <ReceiptModal
        isOpen={!!selected}
        transactionId={selected?.transId}
        onRequestclose={() => setSelected(undefined)}
      />
    </>
  );
};
