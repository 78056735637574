import React, { FC, SyntheticEvent, useCallback } from "react";
import { Button, Divider, Drawer } from "antd";

import { AppState } from "state";
import { Icon } from "components/Icon";
import { Avatar } from "components/Avatar";
import { getHistory } from "helpers/history";
import { Text } from "components/Typography";

import "./drawer.css";
import { userFullName } from "helpers";
import { useTranslation } from "react-i18next";
import styles from "../applayout.module.css";
import icon from "../../../assets/images/language.svg";
import i18n from "i18next";
import { parseBrowserLanguage } from "App";

interface Props {
  activeItem: undefined | keyof typeof navMap;
  user: AppState["customer"];
  onClose: () => void;
  open: boolean;
}

export const MobileMenu: FC<Props> = ({ open, onClose, user, activeItem }) => {
  const { t } = useTranslation();
  const handleNavClick = useCallback(
    (e: SyntheticEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const path = e.currentTarget.href.replace(window.location.origin, "");

      onClose();

      getHistory().pushWithTheme(path);
    },
    [onClose]
  );

  const fullName = userFullName(user);
  const email = user?.email || "@";

  return (
    <Drawer closable={false} placement="right" visible={!!open} onClose={onClose}>
      <div className="bg-light d-flex h-100 flex-column">
        <div className="d-flex flex-row align-items-center sidebar-header">
          <Avatar icon={fullName} />
          <div className="d-flex flex-grow-1 flex-column pl-2 overflow-hidden">
            <Text ellipsis strong size="medium">
              {fullName}
            </Text>
            <Text color="disabled">{email}</Text>
          </div>
        </div>
        <Divider className="m-0" />
        <div className="d-flex flex-column p-4">
          <a
            className="d-flex align-items-center rounded sidebar-button"
            data-active={activeItem === "wallet-dashboard"}
            href="/wallet-dashboard"
            onClick={handleNavClick}
          >
            <Text className="d-flex">
              <Icon className="mr-4" name="wallet" size={24} />
            </Text>
            <Text size="medium">{t("WALLET")}</Text>
          </a>

          <a
            className="d-flex align-items-center rounded sidebar-button"
            data-active={activeItem === "reload"}
            href="/reload"
            onClick={handleNavClick}
          >
            <Text className="d-flex">
              <Icon className="mr-4" name="plus" size={24} />
            </Text>
            <Text size="medium">{t("RELOAD")}</Text>
          </a>

          <a
            className="d-flex align-items-center rounded sidebar-button"
            data-active={activeItem === "profile"}
            href="/profile"
            onClick={handleNavClick}
          >
            <Text className="d-flex">
              <Icon className="mr-4" name="user" size={24} />
            </Text>
            <Text size="medium">{t("PROFILE")}</Text>
          </a>

          <a
            className="d-flex align-items-center rounded sidebar-button"
            href="/profile/personal-info"
            onClick={handleNavClick}
          >
            <Text className="ml-5" color="disabled" size="medium">
              {t("PERSONAL_INFORMATION")}
            </Text>
          </a>
          <a
            className="d-flex align-items-center rounded sidebar-button"
            href="/profile/notifications"
            onClick={handleNavClick}
          >
            <Text className="ml-5" color="disabled" size="medium">
              {t("NOTIFICATION")}
            </Text>
          </a>
          <a
            className="d-flex align-items-center rounded sidebar-button"
            href="/profile/payment"
            onClick={handleNavClick}
          >
            <Text className="ml-5" color="disabled" size="medium">
              {t("PAYMENT")}
            </Text>
          </a>
        </div>

        <div className="d-flex mt-auto flex-column">
          <div className={"languageSelectContainer"}>
            <div className={"currentLanguage"}>
              <img alt="" className={styles.langIcon} src={icon} />
              <span>{i18n.language}</span>
            </div>

            <Button
              className={"changeLanguage"}
              type={"link"}
              onClick={() =>
                i18n.changeLanguage(parseBrowserLanguage(i18n.language) === "fr" ? "en" : "fr")
              }
            >
              {t("CHANGE_LANGUAGE_TO", {
                language: t(parseBrowserLanguage(i18n.language) === "fr" ? "ENGLISH" : "FRENCH"),
              })}
            </Button>
          </div>

          <Divider className="m-0" />
          <a
            className="d-flex align-items-center rounded mx-4 my-2 sidebar-button"
            href="/logout"
            onClick={handleNavClick}
          >
            <Text className="d-flex">
              <Icon className="mr-4" name="logout" size={24} />
            </Text>
            <Text size="medium">{t("LOGOUT")}</Text>
          </a>
        </div>
      </div>
    </Drawer>
  );
};

const navMap = {
  profile: "/profile",
  reload: "/reload",
  "wallet-dashboard": "/wallet-dashboard",
};
