import * as Yup from "yup";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const WatchLocale: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    Yup.setLocale({
      mixed: {
        required: () => t("FIELD_REQUIRED"),
      },
      string: {
        email: () => t("ENTER_VALID_EMAIL"),
      },
    });
  }, [t, language]);
  return null;
};
