import * as Yup from "yup";
import { message, Select } from "antd";
//import { FormikConsumer } from "formik";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo, useState } from "react";

import { apiServices } from "helpers";
import { Link } from "components/Link";
import { Button } from "components/Button";
import { Auth as AuthLayout } from "layouts";
import { Title } from "components/Typography";
import { rememberLogin } from "state/actions";
import { PasswordField } from "components/Form/fields/PasswordField";
import { Form, TextField, Field } from "components/Form";
import { useLazyApiCall, authResult, useSelector, useDispatch, useConfig } from "state";
import { Loader } from "../../components/Loader";
import i18n from "i18next";
import styles from "./common.module.css";
import icon from "../../assets/images/language.svg";
import { RenderDesktop, RenderMobile } from "../../components/RenderMatchMedia";
import { parseBrowserLanguage } from "App";

export const Login: FC = () => {
  const { t } = useTranslation();
  const rememberMe = !!useSelector((s) => s.rememberMe);
  const dispatchState = useDispatch();

  const { dispatch, isLoading, error, status } = useLazyApiCall(
    apiServices.login("", ""),
    authResult
  );

  const config = useConfig();
  const isVE = config.connectorType === "VE";

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          customerCode: Yup.string().required().nullable().default(null),
          password: Yup.string().required().nullable().default(null),
          rememberMe: Yup.boolean().default(false),
        })
        .defined(),
    []
  );

  const initialValues = useMemo(() => schema.cast({ rememberMe }), [rememberMe, schema]);

  type FormData = Yup.InferType<typeof schema>;

  const handleSubmit = ({ password, customerCode, rememberMe }: FormData) => {
    dispatchState(rememberLogin(!!rememberMe));
    dispatch({ body: { customerCode, password } }, 1);
  };

  useEffect(() => {
    if (error) {
      if (
        isVE &&
        status === 400 &&
        !((error as unknown) as string).includes("Your Account is not enabled for My Volante,")
      ) {
        // If it's VE and the status code is 400 while logging in then display a generic error toast.
        message.error(t("INVALID_CREDENTIALS"));
      } else {
        message.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // Commenting this out instead of deleting it, just in case for testing purposes
  /*
  let devAccounts: undefined | string[][] = (process.env.REACT_APP_DEV_ACCOUNTS || "")
    .trim()
    .split(";")
    .map((i) => i.split("/"));

    if (devAccounts && devAccounts.length) {
    if (devAccounts.filter((i) => i.length === 2).length === 0) {
      devAccounts = undefined;
    }
  } else {
    devAccounts = undefined;
  }*/
  const initialized = useSelector((s) => s.initialized);
  const siteTheme = config.amsSite?.siteTheme;
  const accountId = siteTheme?.labels?.["label.auth.account.id"];
  const loginLabel = siteTheme?.labels?.["label.auth.login"];
  const loginButtonText = siteTheme?.labels?.["button.auth.login"];
  const signupButtonText = siteTheme?.labels?.["button.auth.signup"];

  const [currentLang, setCurrentLang] = useState(parseBrowserLanguage(i18n.language));

  return initialized ? (
    <>
      <RenderDesktop>
        <Select
          className={styles.languageSelectorDropdown}
          defaultValue={currentLang}
          size="large"
          value={currentLang}
          onSelect={(val) => {
            setCurrentLang(val);
            i18n.changeLanguage(val);
          }}
        >
          <Select.Option value={"en"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("ENGLISH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
          <Select.Option value={"fr"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("FRENCH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
        </Select>
      </RenderDesktop>

      <RenderMobile>
        <div className={styles.languageSelectContainer}>
          <div className={styles.currentLanguage}>
            <img alt="" className={styles.langIcon} src={icon} />
            <span>{i18n.language}</span>
          </div>

          <Button
            className={"changeLanguage"}
            type={"link"}
            onClick={() =>
              i18n.changeLanguage(parseBrowserLanguage(i18n.language) === "fr" ? "en" : "fr")
            }
          >
            {t("CHANGE_LANGUAGE_TO", {
              language: t(parseBrowserLanguage(i18n.language) === "fr" ? "ENGLISH" : "FRENCH"),
            })}
          </Button>
        </div>
      </RenderMobile>

      <Loader size={"large"} spinning={isLoading}>
        <AuthLayout>
          <Form
            id="loginForm"
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <div className="d-block flex-col">
              <Title className="mb-5" level={1}>
                {loginLabel ? loginLabel : t("LOGIN")}
              </Title>
              <Field
                component={TextField}
                label={accountId ? accountId : t("ACCOUNT_ID")}
                name="customerCode"
                placeholder={t("ACCOUNT_ID")}
              />

              <Field
                autoComplete="off"
                component={PasswordField}
                label={t("PASSWORD")}
                name="password"
                placeholder={t("PASSWORD")}
                type="password"
              />

              <div className="d-flex flex-row mb-4 align-items-center justify-content-end">
                {/* #TODO Uncomment out after "remember me" functionality will be implemented */}
                {/* <Field
              className="mb-0"
              component={CheckboxField}
              label={t("Remember me")}
              name="rememberMe"
            /> */}
                <Link to="/:theme/forgot-password">{t("FORGOT_PASSWORD")}</Link>
              </div>

              <Button block disabled={isLoading} htmlType="submit" type="primary">
                {loginButtonText ? loginButtonText : t("LOG_IN")}
              </Button>

              <div className="text-center mt-4">
                {`${t("DONT_HAVE_ACCOUNT")} `}
                <Link to="/:theme/sign-up">
                  {signupButtonText ? signupButtonText : t("SIGN_UP")}
                </Link>
              </div>
            </div>

            {
              // Commenting this out instead of deleting it, just in case for testing purposes
              /*devAccounts && devAccounts.length > 0 && (
            <FormikConsumer>
              {({ setFieldValue, submitForm }) => (
                <div className="py-4 text-small text-muted">
                  <small>
                    *This is only for dev and will be hidden on prod environment (list of
                    available accounts)
                  </small>
                  {(devAccounts || []).map(([acc, pwd], k) => (
                    <div className="d-block" key={k}>
                      <a
                        href={`#login-as-${acc}/${pwd}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue("customerCode", acc);
                          setFieldValue("password", pwd);
                          setTimeout(() => submitForm(), 500); // its just dev workaround so we dont want to spent time with doing sync form status with submit button
                        }}
                      >{`${acc} / ${pwd}`}</a>
                    </div>
                  ))}
                </div>
              )}
            </FormikConsumer>
                      )*/
            }
          </Form>
        </AuthLayout>
      </Loader>
    </>
  ) : (
    <></>
  );
};
