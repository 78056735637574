import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { Text } from "components/Typography";
import { formatPrice } from "helpers/formatPrice";

import styles from "./autoload-subscribed.module.css";

interface Props {
  onClick?: () => void;
  value: number;
  min: number;
}

export const AutoloadSubscribed: FC<Props> = ({ min, value, onClick }) => {
  const { t } = useTranslation();

  const content = (
    <>
      <Text className="d-block" color="success">
        {t("AUTOLOAD_ENABLED")}
      </Text>
      <Text>
        {t("AUTOLOAD")} <strong>{formatPrice(value)}</strong> {t("WHEN_BALANCE_IS")}{" "}
        <strong>{formatPrice(min)}</strong>
      </Text>
      {onClick && (
        <Icon aria-label="open" className={styles.arrow} name="chevron-right" size={16} />
      )}
    </>
  );

  return onClick ? (
    <button className={styles.root} onClick={onClick}>
      {content}
    </button>
  ) : (
    <div className={styles.root}>{content}</div>
  );
};
